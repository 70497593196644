import '../../styles/pages/index.scss'
import '../components/generateHeroSlider';
import '../components/home-regions-slider';
import '../components/war-slider';
import generateHeroSlider from '../components/generateHeroSlider';
import Splide from '@splidejs/splide';
import generateTabs from '../components/generateTabs'
import '../components/tv-hosts'

document.addEventListener('DOMContentLoaded', generateHeroSlider);

window.Splide = Splide;

generateTabs('sidebar-last', { isLoadMore: false });
generateTabs('sidebar-popular', { isLoadMore: false });

const allVideoSliders = document.querySelectorAll('.splide-video-slider');

allVideoSliders.forEach((itm) => {
  const splide = new Splide(itm, {
    type: 'loop',
    perPage: 4,
    perMove: 1,
    pagination: false,
    gap: 32,
    flickPower: 100,
    breakpoints: {
      768: {
        perPage: 2,
      },
      1024: {
        perPage: 3,
        gap: 24,
      },
    }
  });

  splide.mount();
})

const heroesTabsBtns = document.querySelectorAll('.heroes-tabs .heroes-tabs__tab_btn');
const heroesImages = document.querySelectorAll('.tv-hosts__images .tv-hosts__image');

const handleMouseOver = (idx) => {
  heroesImages[idx].classList.add('tv-hosts__image_active');
  heroesTabsBtns[idx].classList.add('heroes-tabs__tab_btn_active');
}

const handleMouseOut = (idx) => {
  heroesImages[idx].classList.remove('tv-hosts__image_active');
  heroesTabsBtns[idx].classList.remove('heroes-tabs__tab_btn_active');
}


heroesImages.forEach((itm, idx) => {
  itm.addEventListener('mouseover', () => handleMouseOver(idx))
  itm.addEventListener('mouseout', () => handleMouseOut(idx))
})

heroesTabsBtns.forEach((itm, idx) => {
  itm.addEventListener('mouseover', () => handleMouseOver(idx))
  itm.addEventListener('mouseout', () => handleMouseOut(idx))
})
